import { STORAGE_KEYS } from "./storage";
import { getItem } from "./storage";

export async function getTicketCategories(): Promise<any> {
    const response = await getItem(STORAGE_KEYS.ALL_TICKET_CATEGORIES);
    
    if (response) {
        const categories = JSON.parse(response).map((category: any) => {
            return {
                id: category.id,
                label: `${category.translations[0]?.description}` 
            }
        });
        return categories;
    }

    return null;
}