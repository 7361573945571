import localforage from "localforage";

export enum STORAGE_KEYS {
    ALL_CORPORATIONS = 'all-corporations',
    EXECUTED_INTEGRATION_JOBS = 'executed-integration-jobs',
    ALL_TICKET_CATEGORIES = 'all-ticket-categories',
}

export async function getItem(key: string): Promise<string | null> {
    return await localforage.getItem(key);
}

export async function setItem(key: string, value: string): Promise<string> {
    return await localforage.setItem(key, value);
}

export async function removeItem(key: string): Promise<void> {
    return await localforage.removeItem(key);
}

export async function clearStorage(): Promise<void> {
    return await localforage.clear();
}