import { useState, useEffect } from 'react';
import { SelectInput } from "../SelectInput";
import { Alert, AlertTypes } from '../Alert';
import { Button, ButtonTypes } from "../Button";
import { Modal } from "../Modal";
import { getAllTicketCategories, migrateTicketCategories, MigrateTicketCategoriesResponse } from "../../api/tools";
import { Spinner, SpinnerColor } from '../Spinner';
import { getAllCorporations } from '../../helpers/corporation';
import { getTicketCategories } from '../../helpers/ticketCategories';
import { API_ENV, HOCOCO_JOB_SECRETS } from '../../helpers/request';
import { STORAGE_KEYS, setItem } from '../../helpers/storage';
import TextInput, { InputTypes } from '../TextInput';

export default function SendWelcomeEmail() {

    const [corporationId, setCorporationId] = useState(0);
    const [fromCategoryId, setFromCategoryId] = useState(0);
    const [toCategoryId, setToCategoryId] = useState(0);
    const [allCorporations, setAllCorporations] = useState([]);
    const [allCategories, setAllCategories] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [errorResponse, setErrorResponse] = useState('');
    const [successResponse, setSuccessResponse] = useState({} as MigrateTicketCategoriesResponse);
    const [isLoading, setIsLoading] = useState(false);

    function List() {
        const listItems = allCategories.map(category =>
          <li className='list-group-item'>{JSON.stringify(category).replace('{"id":','ID: ').replace('"}','').replace(',"label":"',' - ')}</li>
        );
        return <div>
                    <p className='mt-3 mb-0'>CORPORATION CATEGORIES: {listItems?.length}</p>
                    <ul className='list-group scrollable-list'>{listItems}</ul>
                </div>
      }

    async function handleSubmit(e: Event) {
        e.preventDefault();
        setShowModal(true);
    }


    useEffect(() => {
        getAllCorporations().then(corporations => {
            if (corporations) {
                setAllCorporations(corporations as any);
            }
        })
    })

    useEffect(() => {
        if (corporationId > 0) {

            getAllCategories().then(() => {
                getTicketCategories().then(categories => {
                    setAllCategories(categories);
                });
            });
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [corporationId])


    async function getAllCategories() {
        const categories = await getAllTicketCategories({corporationId});
        if (categories) {
            await setItem(STORAGE_KEYS.ALL_TICKET_CATEGORIES, JSON.stringify(categories));
        }
    }

    async function submit(e: Event) {
        e.preventDefault();
        setIsLoading(true);
        try {
            let response: MigrateTicketCategoriesResponse;
            const secret = HOCOCO_JOB_SECRETS[API_ENV];
            const body = { corporationId, fromCategoryId, toCategoryId, secret }
            response = await migrateTicketCategories(body);
            if (response?.message) {
                setSuccessResponse(response);
                setIsLoading(false);
            } else {
                resetState();
            }
        } catch (err) {
            setErrorResponse(err as string);
            setIsLoading(false);
        }
        resetState();
    }

    function resetState() {
        setFromCategoryId(0);
        setToCategoryId(0);
        setErrorResponse('');
        setShowModal(false);
        setIsLoading(false);
    }

    return (
        <>
            <div className="card p-4 mb-4 full-width">
                <h5 className="card-header">Migrate Ticket Categories</h5>
                <div className="card-body">
                    <div className="row g-3">

                        <div className="col-md-6">
                            <SelectInput
                                label="Corporation"
                                options={
                                    allCorporations
                                }
                                placeholder={
                                    {
                                        value: '',
                                        label: 'Select corporation'
                                    }
                                }
                                value={corporationId}
                                onValue={(option: any) => setCorporationId(option.value)}
                            />
                            {allCategories?.length > 0 && (List())}
                        </div>
                        <div className="col-md-6">
                            <TextInput
                                value={fromCategoryId}
                                onValue={(value: number) => setFromCategoryId(value)}
                                label="From Category ID"
                                placeholder="0"
                                description=""
                                htmlOptions={{
                                    required: true
                                }}
                                type={InputTypes.NUMBER}
                            />
                            <TextInput
                                value={toCategoryId}
                                onValue={(value: number) => setToCategoryId(value)}
                                label="To Category ID"
                                placeholder="0"
                                description=""
                                htmlOptions={{
                                    required: true
                                }}
                                type={InputTypes.NUMBER}
                            />
                        <div className="pt-4">
                            <Button type={ButtonTypes.PRIMARY} text="Migrate Categories" className={!fromCategoryId || !toCategoryId || !corporationId ? 'disabled' : ''} onPress={(e: Event) => handleSubmit(e)} />
                        </div>
                        </div>
                        {showModal && (
                            <Modal
                                title="Details about the action"
                                onConfirm={(e: Event) => submit(e)}
                                onCancel={(e: Event) => setShowModal(false)}
                            >
                                <div>
                                    <p>Are you sure you want to migrate tickets from</p>
                                    <p>Category id {fromCategoryId} to {toCategoryId}?</p>
                                </div>
                            </Modal>
                        )}
                        {errorResponse && (
                            <div className="pt-4">
                                <Alert type={AlertTypes.DANGER} text={errorResponse} />
                            </div>
                        )}
                        {isLoading && (
                            <Spinner color={SpinnerColor.PRIMARY} />
                        )}
                        {successResponse?.message && (
                            <div className="pt-4">
                                <Alert type={AlertTypes.SUCCESS} text={successResponse.message} />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}